var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1100px","persistent":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.model.id ? 'Edición' : 'Creación')+" Stand")])]),_c('v-card-text',{staticClass:"py-0"},[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Código","vid":"code","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"uppercase",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Código","required":""},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"vid":"sales","name":"Categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.standCategories,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Categoría"},model:{value:(_vm.model.standCategoryId),callback:function ($$v) {_vm.$set(_vm.model, "standCategoryId", $$v)},expression:"model.standCategoryId"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Valor en pesos","vid":"copValue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Valor en pesos","error-messages":errors,"outlined":"","dense":"","required":""},model:{value:(_vm.model.copValue),callback:function ($$v) {_vm.$set(_vm.model, "copValue", $$v)},expression:"model.copValue"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Valor en dólares","vid":"usdValue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":"Valor en dólares","error-messages":errors,"outlined":"","dense":"","required":""},model:{value:(_vm.model.usdValue),callback:function ($$v) {_vm.$set(_vm.model, "usdValue", $$v)},expression:"model.usdValue"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Ancho","vid":"width","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Ancho","required":""},model:{value:(_vm.model.width),callback:function ($$v) {_vm.$set(_vm.model, "width", $$v)},expression:"model.width"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Profundidad","vid":"depth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Profundidad","required":""},model:{value:(_vm.model.depth),callback:function ($$v) {_vm.$set(_vm.model, "depth", $$v)},expression:"model.depth"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"vid":"cornerType","name":"Tipo de stand","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.cornerTypes,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Tipo de Stand"},model:{value:(_vm.model.cornerType),callback:function ($$v) {_vm.$set(_vm.model, "cornerType", $$v)},expression:"model.cornerType"}})]}}])})],1),(_vm.model.cornerType)?_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Diferencia Ancho","vid":"widthDifference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Diferencia ancho","required":""},model:{value:(_vm.model.widthDifference),callback:function ($$v) {_vm.$set(_vm.model, "widthDifference", $$v)},expression:"model.widthDifference"}})]}}],null,false,2927749761)})],1):_vm._e(),(_vm.model.cornerType)?_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Diferencia Profundidad","vid":"depthDifference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Diferencia Profundidad","required":""},model:{value:(_vm.model.depthDifference),callback:function ($$v) {_vm.$set(_vm.model, "depthDifference", $$v)},expression:"model.depthDifference"}})]}}],null,false,74583357)})],1):_vm._e(),_c('div',{staticClass:"col-12 py-0"},[_c('validation-provider',{attrs:{"vid":"description","name":"Descripción","rules":"min:0|max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"600","outlined":"","clearable":"","type":"text","rows":"2","error-messages":errors,"clear-icon":"mdi-close-circle","label":"Escribe una breve descripción de la feria"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}])})],1),_c('div',{staticClass:"col-12 py-0"},[_c('v-switch',{attrs:{"color":"accent","label":"¿Activa?"},model:{value:(_vm.model.isActive),callback:function ($$v) {_vm.$set(_vm.model, "isActive", $$v)},expression:"model.isActive"}})],1)])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveStand}},[_vm._v(" Guardar ")]),(_vm.model.id)?_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.onDeleteStand()}}},[_vm._v(" Eliminar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }